<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 18:10:10
 * @LastEditTime: 2021-06-10 17:09:19
-->
<template>
	<div class="activity">
		<div v-if="activityList.length == 0" class="activity-empty">
			<span>您还没有岗位，立即</span>
			<span @click="jobHandle('add')" style="color: #4392FA;cursor: pointer;">去创建</span>
			<span>吧~</span>
		</div>
		<div v-else class="activity-data">
			<div class="activity-data-tab" v-if="userType=='SCHOOL_MANAGER'">
				<tabList :intTabList="tabList" @updateJobList="updateJobList" />
				<span @click="jobHandle('add')" class="data-tab-btn">新增岗位</span>
			</div>
			<div class="activity-data-list">
				<div class="data-list-search">
					<div class="list-search-row">
						<div class="list-search-col">
							<span class="list-search-label">岗位名称：</span>
							<el-input class="list-search-ipt" v-model="searchForm.name" placeholder="请输入"></el-input>
						</div>
						<div class="list-search-col">
							<span class="list-search-label">岗位时间：</span>
							<el-date-picker class="list-search-date" v-model="date" value-format="yyyy-MM-dd" type="monthrange"
								range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期" @change="handleChange($event)">
							</el-date-picker>
						</div>
						<div class="list-search-col">
							<span class="list-search-label">发布状态：</span>
							<el-select class="list-search-select" v-model="searchForm.state" placeholder="请选择">
								<el-option label="是" value="1"></el-option>
								<el-option label="否" value="0"></el-option>
							</el-select>
						</div>
						<!-- <div class="list-search-col">
							<span @click="jobHandle('add')" class="list-search-cancel width-80" v-if="userType=='COLLEGE_MANAGER'">+新增岗位</span>
							<span class="list-search-confirm" @click="jobHandle('query')" v-if="userType=='SCHOOL_MANAGER'">查询</span>
							<span class="list-search-cancel" @click="jobHandle('reset')" v-if="userType=='SCHOOL_MANAGER'">重置</span>
						</div> -->
					</div>
					<div class="list-search-row">
						<!-- <div class="list-search-col" v-if="userType=='SCHOOL_MANAGER'">
							<span class="list-search-label">校外岗状态：</span>
							<el-select class="list-search-select" v-model="searchForm.postStatus" placeholder="请选择">
								<el-option label="校内" value="1"></el-option>
								<el-option label="校外" value="2"></el-option>
							</el-select>
						</div> -->
						
						<div class="list-search-col" v-if="userType=='SCHOOL_MANAGER'">
							<span class="list-search-label">发布人：</span>
							<el-select class="list-search-select" v-model="searchForm.publisher" placeholder="请选择">
								<el-option v-for="(item, index) in accountList" :key="index" :label="item.account"
									:value="item.id">
								</el-option>
							</el-select>
						</div>
						<div class="list-search-col">
							<span class="list-search-label">校区：</span>
							<el-select class="list-search-select" v-model="searchForm.schoolId" placeholder="请选择">
								<el-option v-for="(item, index) in schoolList" :key="index" :label="item.name"
									:value="item.id">
								</el-option>
							</el-select>
						</div>
						<div class="list-search-col" style="flex:1;justify-content: flex-start;margin-right:0;">
							<span @click="jobHandle('add')" class="list-search-cancel width-80" style="margin-right:8px;" v-if="userType=='COLLEGE_MANAGER'">+新增岗位</span>
							<span class="list-search-confirm" @click="jobHandle('query')">查询</span>
							<span class="list-search-cancel" @click="jobHandle('reset')">重置</span>
							<span class="list-search-download">
								<img class="search-download-icon" src="@/assets/images/pc/common/upload.png" alt="">
								<span @click="jobHandle('download')" class="search-download-text">导出数据</span>
							</span>
						</div>
					</div>
				</div>
				<div class="data-list-content">
					<el-table :data="tableData" style="width: 100%">
						<el-table-column type="index" label="序号" width="80" align="center">
							<template slot-scope="scope">
								<span>{{(page - 1) * pageSize + scope.$index + 1}}</span>
							</template>
						</el-table-column>
						<el-table-column prop="name" label="岗位名称"  width="180">
						</el-table-column>
						<el-table-column prop="postTime" label="时间" width="160">
						</el-table-column>
						<el-table-column prop="address" label="地址">
						</el-table-column>
						<el-table-column prop="postTypeName" label="类型">
						</el-table-column>
						<el-table-column prop="admittedNum" label="在岗人数">
						</el-table-column>
						<el-table-column prop="schoolName" label="校区">
						</el-table-column>
						<el-table-column prop="state" label="岗位发布">
							<template slot-scope="scope">
								<el-switch v-model="scope.row.stateValue" :disabled="isDisabled(scope.row)" active-color="#4392FA" inactive-color="#DCDFE6" @change="jobHandle('switch',scope.row)">
								</el-switch>
							</template>
						</el-table-column>
						<el-table-column prop="action" label="操作" width="140px">
							<template slot-scope="scope">
								<div class="list-content-action">
									<span @click="jobHandle('detail',scope.row)">详情</span>
									<el-divider direction="vertical" v-if="(userInfo.id===scope.row.createBy)||userInfo.superAdmin==true"></el-divider>
									<span @click="jobHandle('edit',scope.row)" v-if="(userInfo.id===scope.row.createBy)||userInfo.superAdmin==true">编辑</span>
									<el-divider direction="vertical" v-if="(userInfo.id===scope.row.createBy)||userInfo.superAdmin==true"></el-divider>
									<span @click="jobHandle('delete',scope.row)" v-if="(userInfo.id===scope.row.createBy)||userInfo.superAdmin==true">删除</span>
								</div>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<div class="common-pageNation-pc">
				<el-pagination background @current-change="currentChange" :current-page.sync="page"
					:page-size="pageSize" layout="total, prev, pager, next" :total="totalCount">
				</el-pagination>
			</div>
			</div>
		</div>
	</div>
</template>
<script>
	import storage from 'store'
	import tabList from "../components/tabList.vue"
	import {
		getJobList,
		jobDelete,
		getJobStatus,
		jobExport,
		schoollistAllPc
	} from '@/api/pc/index'
	import {
		accountListPc,
	} from '@/api/pc/user'
	export default {
		components: {
			tabList
		},
		data() {
			return {
				identityPc: "",
				activityList: [{}],
				jobtypeList: [],
				page:1,
				pageSize:10,
				totalCount:0,
				date:[],
				schoolList: [],
				tabList: [{
						label: "全部",
						value: "0",
						name:""
					},
					{
						label: "校内固定",
						value: "1",
						name:"S_FIX"
					},
					{
						label: "校内临时",
						value: "2",
						name:"S_TEMP"
					},
					{
						label: "校外家教",
						value: "3",
						name:"SO_FIX_TUTOR"
					},
					{
						label: "校外兼职",
						value: "4",
						name:"SO_PART_TIME"
					},
				],
				searchForm: {

				},
				tableData: [{}],
				userType:"",
				userInfo:undefined,
				accountList:[]
			}
		},
		created(){
			
		},
		mounted() {
			this.getJobListsData()
			this.getSchoolList()
			this.userType = storage.get("accessInfo").userType
			if(this.userType!=='COLLEGE_MANAGER'){
				this.getAccountList()
			}
			this.userInfo = storage.get("accessInfo")
		},
		methods: {
			//获取校区列表
			getSchoolList() {
				let params = {
					pageIndex: 1,
					pageSize: 999
				}
				schoollistAllPc(params).then((res) => {
					if (res.code == 0) {
						this.schoolList = res.data
					}
				})
			},
			isDisabled(item){
				console.log(this.userInfo.superAdmin)
				if(this.userInfo.superAdmin==true||this.userInfo.id===item.createBy){
					return false
				}else{
					return true
				}
			},
			// 获取账号列表
			getAccountList() {
				let params = {
					pageIndex: 1,
					pageSize: 9999
				}
				accountListPc(params).then((res) => {
					if (res.code == 0) {
						this.accountList = res.data
					}
				})
			},
			//获取岗位信息列表
			getJobListsData(){
				this.searchForm.pageIndex = this.page
				this.searchForm.pageSize = this.pageSize
				this.searchForm.dateType = 2
				getJobList(this.searchForm).then(res=>{
					if(res.code == 0){
						res.data.forEach(el=>{
							if(el.state==1){
								el.stateValue = true
							}else{
								el.stateValue = false
							}
						})
						this.tableData = res.data
						this.totalCount = res.page.totalCount * 1
					}
				})
			},
			currentChange(val) {
				this.pageIndex = val;
				this.getJobListsData();
			},
			handleChange(val){
				this.searchForm.postStarTime = val[0]
				this.searchForm.postEndTime = val[1]
				// this.searchForm.postStarTime = val[0]+'-01'
				// let endDay = new Date(val.toString().split(",")[1])
				// let currTime = 24 * 60 * 60 * 1000; //获取一天时间戳
				// let Year1 = endDay.getFullYear(); //获取结束年
				// let Month1 = endDay.getMonth()+2;  //获取结束月  这里+2是获取月份时候少一月 
				// if(!Month1==13){
				// let nowDate = new Date(''+Year1+'/'+Month1) //运用年和月转换回+2月的日期
				// let nowTime = new Date (nowDate.getTime()-currTime);  //获取到当前时间 
				// // 将取到的时间进行拼接
				// let seperator1 = "-"
				// let year = nowTime.getFullYear()
				// let month = nowTime.getMonth() + 1
				// let strDate = nowTime.getDate()
				// console.log(year,month,strDate,'qwe')
				// if (month >= 1 && month <= 9) {
				// month = "0" + month
				// }
				// // 日期补‘0’
				// if (strDate >= 0 && strDate <= 9) {
				// strDate = "0" + strDate;
				// }
				// // 日期拼接
				// var qqq = year + seperator1 + month + seperator1 + strDate;
				// this.searchForm.postEndTime = qqq
				// }else{
				// 	this.searchForm.postEndTime = val[1]+'-31'
				// }
				},
				
			// },
			updateJobList(data){
				this.searchForm.postType = data
				this.getJobListsData()
			},
			//导出数据
			getExportData(){
				var params = JSON.parse(JSON.stringify(this.searchForm))
				var nowData = new Date().getSeconds()
				params.time = nowData
				jobExport(params).then((res) => {
					console.log(res,'resreses')
					const blob = new Blob([res], {
						type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
					})
					// console.log("downLoadActivity", URL.createObjectURL(blob))
					// window.location.href = URL.createObjectURL(blob)
					// const blob = new Blob([res], {type: "application/vnd.ms-excel;charset=utf-8"})
					if ('download' in document.createElement('a')) {
						// 非IE下载
						const elink = document.createElement('a')
						elink.download = "岗位列表.xlsx"
						elink.style.display = 'none'
						elink.href = URL.createObjectURL(blob)
						document.body.appendChild(elink)
						elink.click()
						URL.revokeObjectURL(elink.href) // 释放URL 对象
						document.body.removeChild(elink)
					} else {
						// IE10+下载
						navigator.msSaveBlob(blob, "岗位列表.xlsx")
					}
				})
			},
			jobHandle(type,row) {
				switch (type) {
					case "add":
						this.$router.push({
							path: "/pc/activity/addJob"
						})
						break;
					case "detail":
						this.$router.push({
							path: "/pc/activity/jobDetail?id="+row.id,
						})
						break;
					case "edit":
						// if(this.userInfo.id!==row.createBy){
						// 	return
						// }
						this.$router.push({
							path: "/pc/activity/addJob?type=edit&&id="+row.id
						})
						break;
					case "delete":
						// if(this.userInfo.id!==row.createBy){
						// 	return
						// }
						this.$confirm('确定删除当前活动信息吗？', '信息提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							jobDelete(row.id).then(res=>{
								if(res.code==0){
									this.$message({
										type: 'success',
										message: '删除成功!'
									});
									this.getJobListsData()
								}
							})
							
						}).catch(() => {});
						break;
					case "download":
						this.getExportData()
						// if (this.$dd.env.platform == "pc") {
						// 	this.$dd.ready(() => {
						// 		this.$dd.biz.util.downloadFile({
						// 			url: "http://peer-circle.oss-cn-hangzhou.aliyuncs.com/misc/202207/a2bf3861f92c4a77838792c926a71fe9.xlsx", //要下载的文件的url
						// 			name: '一个图片.xlsx', //定义下载文件名字
						// 			onProgress: function(msg) {},
						// 			onSuccess: function(result) {},
						// 			onFail: function() {}
						// 		})
						// 	})
						// }
						// window.open(
						// 	"http://peer-circle.oss-cn-hangzhou.aliyuncs.com/misc/202207/a2bf3861f92c4a77838792c926a71fe9.xlsx"
						// 	)
						break;
					case "query":
						this.getJobListsData()
						break;
					case "reset":
						this.searchForm = {}
						this.date=""
						this.getJobListsData()
						break;
					case "switch":
						var params = {
							state:0
						}
						if(row.state==0){
							params.state = 1
						}else {
							params.state=0
						}
						getJobStatus(params,row.id).then(res=>{
							if(res.code==0){
								this.getJobListsData()
							}
						})
						break;
				}
			},
		},
	}
</script>
<style lang="scss" scoped>
	/deep/.list-search-col .el-input__inner {
		height: 32px;
		border-color: #DCDFE6;
		border-radius: 0px 2px 2px 0px;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
	}

	/deep/.list-search-col .el-range-separator {
		line-height: 27px;
	}

	/deep/.list-search-col .el-input__icon.el-range__icon.el-icon-date {
		display: none;
	}

	/deep/.list-search-col .el-date-editor .el-range-input {
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
	}

	/deep/.list-search-col .el-select .el-input .el-select__caret {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	/deep/.data-list-content .el-table th.el-table__cell>.cell {
		text-align: center;
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #909399;
		line-height: 40px;
		background: #F7F8F9;
	}

	/deep/.data-list-content .el-table .el-table__cell {
		padding: 0 0;
	}

	/deep/.data-list-content .el-table .cell {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	/deep/.el-divider--vertical {
		background-color: #4392FA;
	}
	// /deep/.v-modal {
	// 	display: none !important;
	// }
	
	.width-80 {
		width: 80px !important;
	}

	.activity {
		.activity-empty {
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			font-family: PingFang SC;
			font-weight: 400;
			line-height: 20px;
			color: #909399;
			margin: 132px auto 0 auto;
		}

		.activity-data {
			display: flex;
			flex-direction: column;

			.activity-data-tab {
				position: relative;

				.data-tab-btn {
					position: absolute;
					right: 0;
					bottom: 0px;
					font-size: 14px;
					font-family: PingFang SC;
					font-weight: 400;
					line-height: 28px;
					color: #4392FA;
					width: 72px;
					border: 1px solid #4392FA;
					border-radius: 2px;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
				}
			}

			.activity-data-list {
				display: flex;
				flex-direction: column;
				margin-top: 8px;

				.data-list-search {
					display: flex;
					flex-direction: column;

					.list-search-row {
						display: flex;
						align-items: center;
						justify-content: flex-start;
						margin-top: 8px;
						.list-search-col:nth-child(3n){
							margin-right:0;
						}
						.list-search-col {
							width: 300px;
							display: flex;
							align-items: center;
							margin-right: 20px;
							box-sizing: border-box;

							.list-search-label {
								min-width:70px;
								text-align: right;
								font-size: 14px;
								font-family: PingFang SC;
								font-weight: 400;
								line-height: 32px;
								color: #909399;
							}
							.list-search-ipt {
								height: 32px;
								line-height: 32px;
								flex: 1;
							}
							.list-search-date {
								flex: 1;
								height: 32px;
							}
							.list-search-select {
								flex: 1;
								height: 32px;
							}

							.list-search-confirm {
								width: 68px;
								line-height: 32px;
								background: #4392FA;
								border-radius: 2px;
								display: flex;
								align-items: center;
								justify-content: center;
								font-size: 14px;
								font-family: PingFang SC;
								font-weight: 400;
								color: #FFFFFF;
								margin-right: 8px;
								cursor: pointer;
							}

							.list-search-cancel {
								width: 68px;
								line-height: 32px;
								border: 1px solid #4392FA;
								border-radius: 2px;
								display: flex;
								align-items: center;
								justify-content: center;
								font-size: 14px;
								font-family: PingFang SC;
								font-weight: 400;
								color: #4392FA;
								cursor: pointer;
							}

							.list-search-download {
								display: flex;
								align-items: center;
								justify-content: center;
								width: 90px;
								height: 32px;
								border: 1px solid #DCDFE6;
								border-radius: 2px;
								margin-left: auto;
								cursor: pointer;

								.search-download-icon {
									width: 14px;
									height: 14px;
									margin-right: 4px;
								}

								.search-download-text {
									font-size: 14px;
									font-family: PingFang SC;
									font-weight: 400;
									color: #606266;
								}
							}
						}
					}
				}

				.data-list-content {
					border: 1px solid #DCDFE6;
					border-radius: 2px;
					margin-top: 16px;

					.list-content-action {
						display: flex;
						align-items: center;
						justify-content: center;

						span {
							// font-size: 14px;
							font-family: PingFang SC;
							font-weight: 400;
							line-height: 48px;
							color: #4392FA;
							cursor: pointer;
						}
						.blue{
							color: #4392FA;
						}
					}
				}
			}
		}
	}
</style>
